import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import { t } from "@lingui/macro";

import RouterABIJson from "../abi/RouterContract.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { error, info } from "./MessagesSlice";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { setAll, formatMoney } from "../helpers";
import { RootState } from "src/store";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { getBalances } from "./AccountSlice";
import { Web3Provider } from "@ethersproject/providers";

const ierc20ABI = ierc20ABIJson.abi;
const RouterABI = RouterABIJson.abi;

const POOL_FACTORY_CONTRACT_ADDRESS = '0x411b0fAcC3489691f28ad58c47006AF5E3Ab3A28';


const mapToken=(t:any)=>{
  if(t=='LGNS')return 'OHM';
  if(t=='USDT')return 'USDT_REAL';
  if(t=='DAI')return 'DAI';
  return t;
}

const getDecimals = (t:any)=>{
  if(t=='LGNS'||t=='OHM')return "9";
  if(t=='USDT'||t=='USDT_REAL')return "6";
  if(t=="DAI")return "18";
  return "18";
}

export const approveSwap = createAsyncThunk(
  "ido/approveSwap",
  async ({ provider, address, networkID, topToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    console.log("approve thunk");
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    // const signer = provider;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const USDTContract = new ethers.Contract(addresses[networkID].USDT_REAL_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const DAIContract = new ethers.Contract(addresses[networkID].DAI_ADDRESS as string,ierc20ABI, signer) as IERC20;

    console.log("approve thunk 1");

    // const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
    let approveTx;
    try {
      if (topToken == "LGNS") {
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString(),
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (topToken == "DAI") {
        const estimateGas = await DAIContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),
        );
        approveTx = await DAIContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (topToken =='USDT'){
        const estimateGas = await DAIContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits('100000000',6).toString()
        );
        approveTx = await USDTContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits('100000000',6).toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        )
      }

      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_swap";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
        // return {
        //   busdAllowance: "2000",
        // };
      }
    } catch (e: unknown) {
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const approveUSDTDAISwap = createAsyncThunk(
  "ido/approveSwap",
  async ({ provider, address, networkID, topToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    console.log("approve thunk");
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    // const signer = provider;
    const usdtContract = new ethers.Contract(addresses[networkID].USDT_REAL_ADDRESS as string, ierc20ABI, signer) as IERC20;
    
    const DAIContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20ABI, signer) as IERC20;
    console.log("approve thunk 1");

    // const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
    let approveTx;
    try {
      if (topToken == "USDT") {
        const estimateGas = await usdtContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "6").toString(),
        );

        approveTx = await usdtContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "6").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (topToken == "DAI") {
        const estimateGas = await DAIContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),
        );
        approveTx = await DAIContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      }
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_swap";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
        // return {
        //   busdAllowance: "2000",
        // };
      }
    } catch (e: unknown) {
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const approveExchange = createAsyncThunk(
  "ido/approveExchange",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    try {
      approveTx = await aXPHContract.approve(addresses[networkID].Ido_EXCHANGE_ADDRESS, aXPHBalance);
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_exchange";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getExchangeBalance = createAsyncThunk(
  "ido/getExchangeBalance",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const XPHContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;

    const approveAllowance = await aXPHContract.allowance(address, addresses[networkID].Ido_EXCHANGE_ADDRESS);
    const aXPHBalance = await aXPHContract.balanceOf(address);
    const XPHBalance = await XPHContract.balanceOf(address);

    // if()
    return {
      axphAllowance: ethers.utils.formatUnits(String(approveAllowance), 9),
      aXPHBalance: ethers.utils.formatUnits(String(aXPHBalance), 9),
      XPHBalance: ethers.utils.formatUnits(String(XPHBalance), 9),
    };
  },
);

export const getAmountsOut = createAsyncThunk(
  "swap/getAmountsOut",
  async ({ provider, address, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    // const signer = provider;
    // console.log('[debug]Signer:', signer)
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;
    const decimal = getDecimals(topToken);// != "LGNS" ? "18" : "9";

    const amountIn = ethers.utils.parseUnits(amountsIn, decimal);
    console.log("[debug]swap getAmountsOut amountIn", networkID,decimal, amountIn.toBigInt(), topToken,bottomToken);
    
    let amountsOut;
    let addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]

    console.log('[debug]swap maptoken:',mapToken(topToken),addresses[networkID][`${mapToken(topToken)}_ADDRESS`]);

    if(topToken=='LGNS'&&bottomToken=='USDT'){
      addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], 
      addresses[networkID][`DAI_ADDRESS`],
        addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]
    }
    else if(topToken=='USDT'&&bottomToken=='LGNS'){
      addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], 
        addresses[networkID][`DAI_ADDRESS`],
        addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]
    }else 
    {
      addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]
    }
      
    console.log("getAmountsOut addressArr", addressArr);
    try {
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);
      console.log("avc getAmountsOut amounts", amounts);
      const amount = amounts[addressArr.length-1];
      // const amount = topToken != "OHM" ? amounts[1] : amounts[2];

      amountsOut = ethers.utils.formatUnits(String(amount), bottomToken == "LGNS" ? "9" : bottomToken=='USDT'?"6":"18");

      return {
        amountsOut,
      };
    } catch (error) {
      console.log("getAmountsOut error", error);
    }
  },
);



export const getUSDTDAIAmountsOut = createAsyncThunk(
  "swap/getAmountsIn",
  async ({ provider, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;

    const amountIn = ethers.utils.parseUnits(amountsIn, bottomToken == "DAI" ? "6" : "18");
    let addressArr, amounts, amountsOut;

    addressArr = bottomToken == 'DAI' ? [addresses[networkID].USDT_REAL_ADDRESS, addresses[networkID].DAI_ADDRESS] :
      [addresses[networkID].DAI_ADDRESS, addresses[networkID].USDT_REAL_ADDRESS]
    amounts = await routerContract.getAmountsOut(amountIn, addressArr);
    amountsOut = ethers.utils.formatUnits(String(amounts[1]), bottomToken == "DAI" ? "18" : "6");

    return {
      amountsOut,
    };
  },
);


export const getAmountsOutV2 = createAsyncThunk(
  "swap/getAmountsOutV2",
  async ({ provider, address, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;
    const decimal = topToken != "OHM" ? "18" : "9";
    const amountIn = ethers.utils.parseUnits(amountsIn, decimal);

    let amountsOut;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsOut = amountsIn;
    } else {
      const addressArr =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? [
            addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
            addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
          ]
          : topToken == "BUSD" || bottomToken == "BUSD"
            ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
            : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);

      const amount =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? amounts[1]
          : topToken == "BUSD" || bottomToken == "BUSD"
            ? amounts[1]
            : amounts[2];

      amountsOut = ethers.utils.formatUnits(String(amount), bottomToken == "XPHV2" ? "9" : "18");
    }
    return {
      amountsOut,
    };
  },
);

export const clearAmount = createAsyncThunk("swap/clearAmount", async () => {
  return {
    amountsOut: "",
    amountsIn: "",
  };
});

export const getAmountsIn = createAsyncThunk(
  "swap/getAmountsIn",
  async ({ provider, networkID, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;

    const amountOut = ethers.utils.parseUnits(amountsOut, bottomToken == "OHM" ? "9" : "18");
    let addressArr, amounts, amountsIn;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsIn = amountsOut;
    } else {
      addressArr =
        topToken != "OHM" && bottomToken != "OHM"
          ? [addresses[networkID][`${topToken}_ADDRESS`], addresses[networkID][`${bottomToken}_ADDRESS`]]
          : topToken == "BUSD" || bottomToken == "BUSD"
            ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
            : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      amounts = await routerContract.getAmountsIn(amountOut, addressArr);
      amountsIn = ethers.utils.formatUnits(String(amounts[0]), bottomToken == "OHM" ? "18" : "9");
    }
    return {
      amountsIn,
    };
  },
);

export const getAmountsInV2 = createAsyncThunk(
  "swap/getAmountsInV2",
  async ({ provider, networkID, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;

    const amountOut = ethers.utils.parseUnits(amountsOut, bottomToken == "XPHV2" ? "9" : "18");
    let addressArr, amounts, amountsIn;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsIn = amountsOut;
    } else {
      addressArr =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? [addresses[networkID][`${topToken}_ADDRESS`], addresses[networkID][`${bottomToken}_ADDRESS`]]
          : topToken == "BUSD" || bottomToken == "BUSD"
            ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
            : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      amounts = await routerContract.getAmountsIn(amountOut, addressArr);
      amountsIn = ethers.utils.formatUnits(String(amounts[0]), bottomToken == "XPHV2" ? "18" : "9");
    }
    return {
      amountsIn,
    };
  },
);


export const getUSDTDAIAmountsIn = createAsyncThunk(
  "swap/getAmountsIn",
  async ({ provider, networkID, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    const routerContract = new ethers.Contract(addresses[networkID].pancakeRouter as string, RouterABI, signer) as any;

    const amountOut = ethers.utils.parseUnits(amountsOut, bottomToken == "DAI" ? "18" : "6");
    let addressArr, amounts, amountsIn;

    addressArr = bottomToken == 'DAI' ? [addresses[networkID].USDT_REAL_ADDRESS, addresses[networkID].DAI_ADDRESS] :
      [addresses[networkID].DAI_ADDRESS, addresses[networkID].USDT_REAL_ADDRESS]
    amounts = await routerContract.getAmountsIn(amountOut, addressArr);
    amountsIn = ethers.utils.formatUnits(String(amounts[0]), bottomToken == "DAI" ? "6" : "18");

    return {
      amountsIn,
    };
  },
);


export const getSelectToken = createAsyncThunk(
  "swap/getSelectToken",
  async ({ provider, address, networkID, topToken, bottomToken }: any, { dispatch }) => {
    console.log('[DEBUG]getSelectToken:',topToken,bottomToken);

    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // const signer = provider;
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    let topBal, bottomBal;
    let tToken = mapToken(topToken);// === "LGNS" ? "OHM" : topToken;
    let bToken = mapToken(bottomToken);// === "LGNS" ? "OHM" : bottomToken;

    

    console.log(
      "topToken",
      topToken,
      bottomToken,
      addresses[networkID][`${tToken}_ADDRESS`],
      addresses[networkID][`${bToken}_ADDRESS`],
    );

    const topTokenContract = new ethers.Contract(
      addresses[networkID][`${tToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;

    const bottomTokenContract = new ethers.Contract(
      addresses[networkID][`${bToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    console.log("topToken", provider, signer, address);
    try {
      topBal = await topTokenContract.balanceOf(address);

      bottomBal = await bottomTokenContract.balanceOf(address);

      topBal = ethers.utils.formatUnits(topBal, topToken == "LGNS" ? "9" : topToken=="USDT"?"6":"18");
      bottomBal = ethers.utils.formatUnits(bottomBal, bottomToken == "LGNS" ? "9" : bottomToken=="USDT"?"6": "18");
      console.log("topToken topBal", topBal, bottomBal);
      return { topBal, bottomBal };
    } catch (error) {
      console.log("topToken err", error);
    }
  },
);

export const getUSDTDAISelectToken = createAsyncThunk(
  "swap/getUSDTDAISelectToken",
  async ({ provider, address, networkID, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // const signer = provider;
    console.log('[debug]provider:',provider);
    
    const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
    let topBal, bottomBal;
    let tToken = topToken === "USDT" ? "USDT_REAL" : topToken;
    let bToken = bottomToken === "USDT" ? "USDT_REAL" : bottomToken;
    console.log(
      "topToken",
      topToken,
      bottomToken,
      addresses[networkID][`${tToken}_ADDRESS`],
      addresses[networkID][`${bToken}_ADDRESS`],
    );

    const topTokenContract = new ethers.Contract(
      addresses[networkID][`${tToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;

    const bottomTokenContract = new ethers.Contract(
      addresses[networkID][`${bToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    console.log("topToken", provider, signer, address);
    try {
      topBal = await topTokenContract.balanceOf(address);

      bottomBal = await bottomTokenContract.balanceOf(address);

      topBal = ethers.utils.formatUnits(topBal, topToken == "USDT" ? "6" : "18");
      bottomBal = ethers.utils.formatUnits(bottomBal, bottomToken == "USDT" ? "6" : "18");
      console.log("topToken topBal", topBal, bottomBal);
      return { topBal, bottomBal };
    } catch (error) {
      console.log("topToken err", error);
    }
  },
);

export const swapToken = createAsyncThunk(
  "swap/swapToken",
  async ({ provider, address, networkID, amountsIn, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = 10;
      console.log("avc slippage", slippage);
      // const signer = provider;
      const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
      const amountIn = ethers.utils.parseUnits(amountsIn, getDecimals(topToken));

      const amountOut = amountsOut * (1 - slippage / 100);
      console.log("amountOut", amountOut);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "LGNS" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(String(amountOut.toFixed(6)), getDecimals(bottomToken));

      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer,
      ) as any;

      // const addressArr = type
      //   ? [addresses[networkID].DAI_ADDRESS, addresses[networkID].OHM_ADDRESS]
      //   : [addresses[networkID].OHM_ADDRESS, addresses[networkID].DAI_ADDRESS];
      let addressArr
      if(topToken=='LGNS'&&bottomToken=='USDT'){
        addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], 
        addresses[networkID][`DAI_ADDRESS`],
          addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]
      }
      else if(topToken=='USDT'&&bottomToken=='LGNS'){
        addressArr =[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], 
          addresses[networkID][`DAI_ADDRESS`],
          addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]]
      }else 
      addressArr=[addresses[networkID][`${mapToken(topToken)}_ADDRESS`], addresses[networkID][`${mapToken(bottomToken)}_ADDRESS`]];

      console.log('[debug]swap exchange:',amountIn,amountOutMin,addressArr);
      let approveTx;

      try {
        
        const deadline: number = Number(localStorage.getItem("deadline")) || 5;
        console.log('[debug]swap exchange,deadline:',deadline);
        approveTx = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountIn,
          amountOutMin,
          addressArr,
          address,
          Date.now() + 1000 * 60 * deadline,
        );
        console.log('[debug]swap exchange tx:',approveTx);  
        const text = "Swap";
        const pendingTxnType = "Swap_TOKEN";
        if (approveTx) {
          dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
          await approveTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e: unknown) {

        if ((e as any).code == "ACTION_REJECTED") {
          dispatch(error(t`User denied transaction signature.`));
          // dispatch(error((e as any).message));
        } else if (e == "cancel") {
          dispatch(error(t`User denied transaction signature.`));
        } else {
          // dispatch(error((e as any).message));
          dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
        }
        return;
      } finally {
        if (approveTx) {
          dispatch(clearPendingTxn(approveTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  },
);

export const swapUSDTDAIToken = createAsyncThunk(
  "swap/swapUSDTDAIToken",
  async ({ provider, address, networkID, amountsIn, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = 10;
      console.log("avc slippage", slippage);
      // const signer = provider;
      const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
      const amountIn = ethers.utils.parseUnits(amountsIn, topToken == "USDT" ? "6" : "18");

      const amountOut = amountsOut * (1 - slippage / 100);
      console.log("[debug]swap2 amountOut", amountOut);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "LGNS" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(String(amountOut.toFixed(6)), bottomToken == "DAI" ? "18" : "6");
      console.log("[debug]swap2 amountOutMin:", amountOutMin);
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer,
      ) as any;

      // const addressArr = type
      //   ? [addresses[networkID].DAI_ADDRESS, addresses[networkID].OHM_ADDRESS]
      //   : [addresses[networkID].OHM_ADDRESS, addresses[networkID].DAI_ADDRESS];
      const addressArr =
        topToken == "USDT"
          ? [addresses[networkID][`USDT_REAL_ADDRESS`], addresses[networkID][`DAI_ADDRESS`]]
          : [addresses[networkID][`DAI_ADDRESS`], addresses[networkID][`USDT_REAL_ADDRESS`]];
      let approveTx;
      const deadline: number = Number(localStorage.getItem("deadline")) || 5;
      try {
        approveTx = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountIn,
          amountOutMin,
          addressArr,
          address,
          Date.now() + 1000 * 60 * deadline,
        );

        const text = "Swap";
        const pendingTxnType = "Swap_TOKEN";
        if (approveTx) {
          dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
          await approveTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e: unknown) {
        if ((e as any).code == "ACTION_REJECTED") {
          dispatch(error(t`User denied transaction signature.`));
          // dispatch(error((e as any).message));
        } else if (e == "cancel") {
          dispatch(error(t`User denied transaction signature.`));
        } else {
          // dispatch(error((e as any).message));
          dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
        }
        return;
      } finally {
        if (approveTx) {
          dispatch(clearPendingTxn(approveTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  },
);


// export const swapUSDTDAITokenV3 = createAsyncThunk("swap/swapUSDTDAITokenV3",,
// async({provider, signer, tokenIn, tokenOut, amountIn}:any)=>{

//   let pool_address = computePoolAddress({
//     factoryAddress: POOL_FACTORY_CONTRACT_ADDRESS,
//     tokenA: tokenIn,
//     tokenB: tokenOut,
//     fee: FeeAmount.LOW,
//   });

//   let pool_contract = new ethers.Contract(pool_address, IUniswapV3PoolABI, provider);

//   let pool_info = await Promise.all([
//     pool_contract.token0(),
//     pool_contract.token1(),
//     pool_contract.fee(),
//     pool_contract.tickSpacing(),
//     pool_contract.liquidity(),
//     pool_contract.slot0(),
//   ]).then(([ token0, token1, fee, tickSpacing, liquidity, slot0 ]) => ({
//     token0,
//     token1,
//     fee,
//     tickSpacing,
//     liquidity,
//     sqrtPriceX96: slot0[0],
//     tick: Number(slot0[1]),
//   }));

//   let pool = new Pool(
//     tokenIn,
//     tokenOut,
//     FeeAmount.LOW,
//     pool_info.sqrtPriceX96.toString(),
//     pool_info.liquidity.toString(),
//     pool_info.tick
//   );

//   let swap_route = new Route([ pool ], tokenIn, tokenOut);
//   let amountOut = await getOutputQuote(provider, swap_route, tokenIn, amountIn);

//   let trade = Trade.createUncheckedTrade({
//     route: swap_route,
//     inputAmount: CurrencyAmount.fromRawAmount(tokenIn, amountIn.toString()),
//     outputAmount: CurrencyAmount.fromRawAmount(tokenOut, amountOut),
//     tradeType: TradeType.EXACT_INPUT,
//   });

//   let params = SwapRouter.swapCallParameters([ trade ], {
//     slippageTolerance: new Percent(50, 10_000), // 50 bips, or 0.50%
//     deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
//     recipient: signer.address,
//   });

//   let router = new Contract(
//     SWAP_ROUTER_ADDRESS,
//     ['function multicall(bytes[] calldata data) external payable returns (bytes[] memory results)'],
//     signer
//   );

//   return router.multicall([params.calldata]);

// })

export interface ISwapSlice {
  busdAllowance: string;
  loading: boolean;
  isWhiteeListed: boolean;
  showBoughtSuccessful: boolean;
  totalAmount: string;
  remaining: string;
  totalSubscription: string;
  axphAllowance: string;
  aXPHBalance: string;
  XPHBalance: string;
  isSubscriptionSuccessful: boolean;
  amountsIn: string;
  amountsOut: string;
  topBal: string;
  bottomBal: string;
}

const initialState: ISwapSlice = {
  busdAllowance: "",
  loading: false,
  isWhiteeListed: false,
  showBoughtSuccessful: false,
  isSubscriptionSuccessful: false,
  totalAmount: "",
  remaining: "",
  totalSubscription: "",
  axphAllowance: "",
  aXPHBalance: "",
  XPHBalance: "",
  amountsIn: "",
  amountsOut: "",
  topBal: "",
  bottomBal: "",
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getExchangeBalance.pending, state => {
        state.loading = true;
      })
      .addCase(getExchangeBalance.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getExchangeBalance.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(swapToken.pending, state => {
        state.loading = true;
      })
      .addCase(swapToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(swapToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(swapUSDTDAIToken.rejected,(state,{error})=>{
        state.loading = false;
      })
      .addCase(getAmountsOut.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsOut.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOut.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsOutV2.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsOutV2.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOutV2.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsIn.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsIn.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsIn.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsInV2.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsInV2.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsInV2.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getSelectToken.pending, state => {
        state.loading = true;
      })
      .addCase(getSelectToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getSelectToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getUSDTDAISelectToken.fulfilled,(state,action)=>{
        setAll(state,action.payload);
      })
      .addCase(clearAmount.pending, state => {
        state.loading = true;
      })
      .addCase(clearAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(clearAmount.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export default swapSlice.reducer;
const baseInfo = (state: RootState) => state.swap;
export const getIdoState = createSelector(baseInfo, swap => swap);
